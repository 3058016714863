.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 3%);
}
.fixed_header tbody tr td {
  overflow: auto;
  padding: 20px;
  text-align: left;
  width: 300px;
}
/* .Sequence .fixed_header tbody tr td,
.Sequence .fixed_header thead tr th
 {
    width: 550px;
} */

.fixed_header thead tr th {
  background: white;
  color: #20a8d8;
  font-weight: 500;
  text-align: left;
  padding: 20px;
  width: 300px;
}
.fixed_header .actions {
  display: flex;
}

.fixed_header .actions button {
  margin-right: 10px;
}

tr.helperContainerClass td {
  width: 400px;
  padding: 20px;
}

tr.helperContainerClass .actions button {
  margin-right: 10px;
  float: left;
}
.handle {
  cursor: grab;
  margin-right: 10px;
  float: left;
}
.BaseTable__row {
  display: flex;
  align-items: center;
}
.BaseTable__header-row--customized {
  display: flex;
}

.BaseTable__row:nth-child(odd) {
  background-color: #f7f7f7;
}
.BaseTable__row-cell {
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
}
.BaseTable__header {
  position: absolute !important;
  top: 0;
}

.BaseTable__table.BaseTable__table-main {
  position: relative;
  padding-top: 64px;
  box-shadow: 5px 5px 20px rgb(0 0 0 / 3%);
}
.tableMain {
  width: 100%;
  height: 70vh;
}
.headerTitle {
  padding: 20px;
  color: #20a8d8;
  font-weight: 500;
}
.BaseTable__row-cell:first-child,
.headerTitle:first-child {
  padding-left: 68px;
}
