@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Segoe UI', system-ui, sans-serif;
  }
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.rc-table-thead {
  text-align: left;
  color: #20a8d8;
  background: #fff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.03);
}

th {
  font-weight: 500;
}

.rc-table-cell {
  padding: 20px 0;
}

td.rc-table-cell {
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
}

.rc-table-tbody tr:nth-child(odd) {
  background-color: #f7f7f7;
}

.green-scroll-bar::-webkit-scrollbar {
  overflow: 'auto';
  width: 3px;
}

.green-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #4dbd74;
  border-radius: 2px;
}

.green-scroll-bar::-webkit-scrollbar-track:vertical {
  background-color: #f4f4f4;
  border-radius: 2px;
}

.text_bold::placeholder {
  font-weight: 700;
  font-size: 18px;
  color: #5c5c5c;
}
.checkbox {
  color: #20a8d8;
  transform: scale(0.8);
}
.rc-table table {
  width: 100%;
}
.addCompany input::placeholder {
  color: #5c5c5c;
  font-size: 16px;
}
.addCompany input.text_bold::placeholder {
  font-weight: 700;
  font-size: 18px;
}

.rc-table table {
  width: 100%;
}
.checkboxCustom .MuiSvgIcon-root {
  transform: scale(0.8);
}
.checkboxCustom path {
  color: #20a8d8;
}
.checkboxCustom .MuiFormControlLabel-label {
  font-size: 14px;
  color: #5c5c5c;
}
.checkboxCustom .MuiButtonBase-root {
  padding-top: 0;
  padding-bottom: 0;
}
.scrollbarCustom::-webkit-scrollbar {
  width: 4px;
}
.scrollbarCustom::-webkit-scrollbar-track {
  background: #eaeaea;
}
.scrollbarCustom::-webkit-scrollbar-thumb {
  background: #4dbd74;
}
.customRadio span {
  position: relative;
  padding-left: 20px;
}

.customRadio span:before {
  content: '';
  width: 15px;
  height: 15px;
  border: 2px solid #20a8d8;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 2px;
}

.customRadio input[type='radio'] {
  opacity: 0;
  display: none;
}
.customRadio input[type='radio']:checked + span::before {
  background-color: #20a8d8;
}
.customRadio input[type='radio']:checked + span:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: 3px;
  top: 4px;
  font-size: 9px;
  color: #fff;
}

.customFilterCheck {
  position: relative;
  padding-left: 20px;
}

.customFilterCheck span:before {
  content: '';
  width: 15px;
  height: 15px;
  border: 2px solid #20a8d8;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 3px;
}

.customFilterCheck input[type='checkbox'] {
  opacity: 0;
  display: none;
}
.customFilterCheck input[type='checkbox']:checked + span::before {
  background-color: #20a8d8;
}
.customFilterCheck input[type='checkbox']:checked + span:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: 3px;
  top: 4px;
  font-size: 9px;
  color: #fff;
}

.customCheck {
  position: relative;
  padding-left: 20px;
}

.customCheck span:before {
  content: '';
  width: 15px;
  height: 15px;
  border: 2px solid #20a8d8;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 3px;
}

.customCheck input[type='checkbox'] {
  opacity: 0;
  display: none;
}
.customCheck input[type='checkbox']:checked + span::before {
  background-color: #20a8d8;
}
.customCheck input[type='checkbox']:checked + span:after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  left: 3px;
  top: 4px;
  font-size: 9px;
  color: #fff;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  left: -17px !important;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  margin: -8px auto 0 !important;
}
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  margin: 0 auto -8px !important;
}

.inputCustom::-webkit-outer-spin-button,
.inputCustom::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.inputCustom {
  outline: none;
  -moz-appearance: textfield;
}
.nodataRow {
  background-color: #f7f7f7;
  font-size: 14px;
  line-height: 19px;
  color: #5c5c5c;
}
tbody.rc-table-tbody > tr.rc-table-placeholder > td.rc-table-cell {
  text-align: center;
}
.file-drop {
  width: 100%;
  height: 350px;
  background-color: #f1f5f9;
  margin: auto;
  /* height: 210px; */
  border-radius: 20px;
  margin-top: auto;
  border: 1px dashed #688ee8;
}
.file-drop-target {
  height: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.file-drop-dragging-over-target {
  opacity: 0.3;
}
.placeholder {
  font-size: 14px;
  color: #3d4852;
  font-weight: 500;
  text-align: center;
}
.browsePopUP {
  color: #688ee8;
  cursor: pointer;
}
/* .MainContainerAddMedia{
    position: relative;
  } */
/* .UploadImagePopUp{
    border: 1px solid red;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgb(67, 68, 69, 0.1);
    backdrop-filter: blur(5px);
  }
  .UploadImagePopUp1{
    border: 1px solid blue;
    width: 90%;
    height: 90vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
    z-index:100;
    padding: 30px;
  } */

.media-filename {
  display: -webkit-box;
  max-width: 130px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
