.editor-wrapper {
}

.editor-toolbar {
  margin-bottom: 0;
  background-color: #f6f6f6;
  padding: 12px 20px;
}

.editor-toolbar > div {
  margin: 0;
}

.editor-toolbar .rdw-option-wrapper {
  background-color: #f6f6f6;
}

.editor {
  height: 360px;
  border: 1px solid #ededed;
  background-color: #fff;
  padding: 0 20px;
}
.ql-editor {
  min-height: 250px;
}
.ql-toolbar.ql-snow {
  background-color: #f6f6f6;
  border-color: #e5e7eb !important;
  border-radius: 5px 5px 0 0;
}
.ql-container.ql-snow {
  border-color: #e5e7eb !important;
  border-radius: 0 0 5px 5px;
}
